import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { v4 as uuid } from "uuid";

import { appStore } from "./appStore";
import blackFrameImage from "../images/frames/black-frame.png";
import whiteFrameImage from "../images/frames/white-frame.png";
import noFrameImage from "../images/frames/no-frame.png";
import naturalFrameImage from "../images/frames/natural-frame.png";
import { fakeTrackLevels1 } from "./FakeWaveForms";
import { getSingleTrackWaveformV2 } from "../util_code/api";

function formatMillisecondsToMinutes(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export const FrameChoicePricingTypes = [
  {
    image: noFrameImage,
    name: "No Frame (Poster Only)",
    label: "Poster Only",
    price: {
      "16 X 20": 46,
      "18 X 24": 49,
      "24 X 36": 52,
    },
  },
  {
    image: blackFrameImage,
    name: "Black Gallery Frame",
    label: "Black Gallery",
    price: {
      "16 X 20": 112,
      "18 X 24": 118,
      "24 X 36": 239,
    },
  },
  {
    image: whiteFrameImage,
    name: "White Gallery Frame",
    label: "White Gallery",
    price: {
      "16 X 20": 112,
      "18 X 24": 118,
      "24 X 36": 239,
    },
  },
  {
    image: naturalFrameImage,
    name: "Natural Gallery Frame",
    label: "Natural Gallery",
    price: {
      "16 X 20": 112,
      "18 X 24": 118,
      "24 X 36": 239,
    },
  },
];

export const SinglePosterSizeOptions = [
  {
    name: "Small",
    size: "8.5 X 11",
    value: "8.5 X 11",
    label: "8.5” X 11”",
    aspectRatio: "8.5 / 11",
    width: "35%",
    price: 66.99,
  },
  {
    name: "Medium",
    size: "11 X 14",
    value: "11 X 14",
    label: "11” X 14”",
    aspectRatio: "11 / 14",
    width: "45%",
    price: 74.99,
  },
  {
    name: "Large (poster)",
    size: "16 X 20",
    value: "16 X 20",
    label: "16” X 20”",
    aspectRatio: "16 / 20",
    width: "66%",
    price: 79.99,
  },
  {
    name: "Gallery (poster)",
    size: "18 X 24",
    value: "18 X 24",
    label: "18” X 24”",
    aspectRatio: "18 / 24",
    width: "100%",
    price: 86.99,
  },
];

export const SinglePosterFrameChoicePricingTypes = [
  {
    image: noFrameImage,
    name: "No Frame (Poster Only)",
    price: {
      "8.5 X 11": 47.00,
      "11 X 14": 49.00,
      "16 X 20": 52.00,
      "18 X 24": 54.00,
    },
  },
  {
    image: blackFrameImage,
    name: "Black Gallery Frame",
    price: {
      "8.5 X 11": 99.00,
      "11 X 14": 109.00,
      "16 X 20": 119.00,
      "18 X 24": 129.00,
    }
  },
  {
    image: whiteFrameImage,
    name: "White Gallery Frame",
    price: {
      "8.5 X 11": 99.00,
      "11 X 14": 109.00,
      "16 X 20": 119.00,
      "18 X 24": 129.00,
    }
  },
  {
    image: naturalFrameImage,
    name: "Natural Gallery Frame",
    price: {
      "8.5 X 11": 99.00,
      "11 X 14": 109.00,
      "16 X 20": 119.00,
      "18 X 24": 129.00,
    }
  },
];

export const PosterSizeOptions = ["16 X 20", "18 X 24", "24 X 36"];
export const PosterSizeOptionsLabels = {
  "16 X 20": "Standard",
  "18 X 24": "Large",
  "24 X 36": "Gallery"
};
export const PosterSizeOptionsLabelsArray = [
  { label: "16” x 20”", value: "16 X 20", aspectRatio: "16 / 20", width: "66%" },
  { label: "18” x 24”", value: "18 X 24", aspectRatio: "18 / 24", width: "75%" },
  { label: "24” x 36”", value: "24 X 36", aspectRatio: "24 / 36", width: "100%" },
]; // TODO don't be lazy and remove one of thses

export const QRCodeMessageExtraCost = 20;

export const AUTO_GENERATED_ENUM = "Auto-Generated";
export const CUSTOM_ENUM = "Custom";

export class PosterStore {
  constructor() {
    this.uid = uuid();
    this.rendering = false;
    this.error = null;
    this.alternateSoundWaveSelectModalOpen = false;
    this.loadingSoundWave = false;
    this.loadingAlbumBase65PreviewImage = false;
    this.errorLoadingBase64PreviewImage = false;

    this.isTesting = false;

    this.loadingAlbumBase65PreviewImage = false;
    this.errorLoadingBase64PreviewImage = false;

    this.albumId = null;
    this.album = {};
    this.albumTracks = [];
    this.albumTitle = ""; // Actual text to be used, as user can edit album title
    this.description = "";
    this.customDescription = "";
    this.descriptionType = undefined;
    this.mostUsedColor = [];
    this.trackLevels = [];
    this.loadedWaveForm = false;
    this.albumBase64PreviewImage = "";
    this.albumTitleFontSize = "medium"; // small,medium,large

    // For single poster
    this.isSingleTrackPoster = false;
    this.song = null;
    this.songName = null;
    this.trackId = null;
    this.customLocation = "";
    this.customLatLng = null;
    this.customDatetime = null;
    this.artist = null;
    this.artistFontColor = "#b19d91";
    this.titleFontSize = "25px";
    this.artistFontSize = "25px";
    this.songDuration = null;
    this.fontFamily = "Arial";
    this.fontWeightForTest = 800;
    this.hideLocation = false;
    this.hideDescription = false;
    this.hideDate = false;
    this.hideTime = false;
    this.songTrackLevel = undefined; // undefined or array
    this.songDuration = undefined;
    this.songMessage = undefined; // undefined means it was not selected, a blank value means there was an error and they need to fill in the data

    // Customizable options
    this.theme = "light";

    this.soundWaveTheme = "dark";
    this.trackTheme = "dark";
    this.alignment = "start";

    this.posterColor = undefined; //  "#eae8e6"
    this.posterSize = undefined; // "16 X 20"
    this.frameChoice = undefined; // "No Frame (Poster Only)"

    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSongName(newValue) {
    this.songName = newValue;
  }

  set setterSongName(newValue) {
    this.songName = newValue;
  }

  setSongDuration(newValue) {
    this.songDuration = newValue;
  }

  set setterSongDuration(newValue) {
    this.songDuration = newValue;
  }

  setArtist(newValue) {
    this.artist = newValue;
  }

  set setterArtist(newValue) {
    this.artist = newValue;
  }

  setTrackId(newValue) {
    this.trackId = newValue;
  }

  set setterTrackId(newValue) {
    this.trackId = newValue;
  }

  setIsTesting(newValue) {
    this.isTesting = newValue;
  }

  set setterIsTesting(newValue) {
    this.isTesting = newValue;
  }

  setLoadingAlbumBase65PreviewImage(newValue) {
    this.loadingAlbumBase65PreviewImage = newValue;
  }

  setHideLocation(newValue) {
    this.hideLocation = newValue;
  }

  set setterHideLocation(newValue) {
    this.hideLocation = newValue;
  }

  setHideTime(newValue) {
    this.hideTime = newValue;
  }

  set setterHideTime(newValue) {
    this.hideTime = newValue;
  }

  setHideDescription(newValue) {
    this.hideDescription = newValue;
  }

  set setterHideDescription(newValue) {
    this.hideDescription = newValue;
  }

  setLocation(newValue) {
    this.location = newValue;
  }

  set setterLocation(newValue) {
    this.location = newValue;
  }

  setHideDate(newValue) {
    this.hideDate = newValue;
  }

  set setterHideDate(newValue) {
    this.hideDate = newValue;
  }

  setArtistFontSize(value) {
    this.artistFontSize = value;
  }

  set setterArtistFontSize(value) {
    this.artistFontSize = value;
  }

  setTitleFontSize(value) {
    this.titleFontSize = value;
  }

  set setterTitleFontSize(value) {
    this.titleFontSize = value;
  }

  setAlbumTitleFontSize(value) {
    this.albumTitleFontSize = value;
  }

  set setterAlbumTitleFontSize(value) {
    this.albumTitleFontSize = value;
  }

  setRendering(rendering) {
    this.rendering = rendering;
  }

  set setterRendering(rendering) {
    this.rendering = rendering;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  set setterLoading(loading) {
    this.loading = loading;
  }

  setAlbumId(albumId) {
    this.albumId = albumId;
  }

  set setterAlbumId(albumId) {
    this.albumId = albumId;
  }

  setAlbum(album) {
    this.album = album;
  }

  set setterAlbum(album) {
    this.album = album;
  }

  setAlbumTitle(newValue) {
    if (newValue && newValue.length > 80) return;
    this.albumTitle = newValue;
  }

  set setterAlbumTitle(newValue) {
    if (newValue && newValue.length > 80) return;
    this.albumTitle = newValue;
  }

  clearAlbum() {
    this.album = {};
  }

  clearSong() {
    if (this.loading) return;
    this.song = {};
    this.alternateSoundWaveSelectModalOpen = false;
  }

  setTrackTheme(trackTheme) {
    this.trackTheme = trackTheme;
  }

  set setterTrackTheme(trackTheme) {
    this.trackTheme = trackTheme;
  }

  setSoundWaveTheme(soundWaveTheme) {
    this.soundWaveTheme = soundWaveTheme;
  }

  set setterSoundWaveTheme(soundWaveTheme) {
    this.soundWaveTheme = soundWaveTheme;
  }

  setSong(song) {
    this.song = song;
  }

  set setterSong(song) {
    this.song = song;
  }

  setIsSingleTrackPoster(isSingleTrackPoster) {
    this.isSingleTrackPoster = isSingleTrackPoster;
  }

  set setterIsSingleTrackPoster(isSingleTrackPoster) {
    this.isSingleTrackPoster = isSingleTrackPoster;
  }

  setCustomLocation(location) {
    this.customLocation = location;
  }

  set setterCustomLocation(location) {
    this.customLocation = location;
  }

  setCustomLatLng(latLng) {
    this.customLatLng = latLng;
  }

  set setterCustomLatLng(latLng) {
    this.customLatLng = latLng;
  }

  setCustomDatetime(date) {
    this.customDatetime = date;
  }

  set setterCustomDatetime(date) {
    this.customDatetime = date;
  }

  setAlbumAlignment(alignment) {
    this.alignment = alignment;
  }

  set setterAlbumAlignment(alignment) {
    this.alignment = alignment;
  }

  setTheme(theme) {
    this.theme = theme;
  }

  set setterTheme(theme) {
    this.theme = theme;
  }

  setPosterColor(color) {
    this.posterColor = color;
  }

  set setterPosterColor(color) {
    this.posterColor = color;
  }

  setArtistFontColor(color) {
    this.artistFontColor = color;
  }

  set setterArtistFontColor(color) {
    this.artistFontColor = color;
  }

  setPosterSize(size) {
    this.posterSize = size;
  }

  set setterPosterSize(size) {
    this.posterSize = size;
  }

  setFrameChoice(frameChoice) {
    this.frameChoice = frameChoice;
  }

  set setterFrameChoice(frameChoice) {
    this.frameChoice = frameChoice;
  }

  setDescription(description) {
    this.description = description;
  }

  set setterDescription(description) {
    this.description = description;
  }

  setCustomDescription(description) {
    this.customDescription = description;
  }

  set setterCustomDescription(description) {
    this.customDescription = description;
  }

  setDescriptionType(descriptionType) {
    this.descriptionType = descriptionType;
  }

  set setterDescriptionType(descriptionType) {
    this.descriptionType = descriptionType;
  }

  setErrorLoadingBase64PreviewImage(newValue) {
    this.errorLoadingBase64PreviewImage = newValue;
  }

  setAlbumBase64PreviewImage(base64Image) {
    this.albumBase64PreviewImage = base64Image;
  }

  set setterAlbumBase64PreviewImage(base64Image) {
    this.albumBase64PreviewImage = base64Image;
  }

  setTrackLevels(newTrackLevels, trackId) {
    this.trackLevels = this.trackLevels.map(track => {
      if (trackId !== track.trackId) return track;
      return {
        ...track,
        levels: newTrackLevels
      };
    });
    this.alternateSoundWaveSelectModalOpen = false;
  }

  set setterSongTrackLevel(newValue) {
    this.songTrackLevel = newValue;
  }

  setSingleSongTrackLevel(newTrackLevels) {
    this.songTrackLevel = newTrackLevels;
    this.alternateSoundWaveSelectModalOpen = false;
  }

  setSongMessage(newValue) {
    this.songMessage = newValue;
  }

  set setterSongMessage(newValue) {
    this.songMessage = newValue;
  }

  get price() {
    if (!this.frameChoice || !this.posterSize) return 0;
    if (this.song) {
      const frameChoice = SinglePosterFrameChoicePricingTypes.find(
        (framePricingType) => framePricingType.name === this.frameChoice
      );
      if (this.songMessage !== undefined) {
        return frameChoice.price[this.posterSize] + QRCodeMessageExtraCost;
      }
      return frameChoice.price[this.posterSize];
    }
    const frameChoice = FrameChoicePricingTypes.find(
      (framePricingType) => framePricingType.name === this.frameChoice
    );
    return frameChoice.price[this.posterSize];
  }

  enableCustomDescription() {
    this.descriptionType = CUSTOM_ENUM;
  }

  enableAutoGeneratedDescription() {
    this.descriptionType = AUTO_GENERATED_ENUM;
  }

  isCustomDescriptionEnabled() {
    return this.descriptionType === CUSTOM_ENUM;
  }

  isAutoGeneratedDescriptionEnabled() {
    return this.descriptionType === AUTO_GENERATED_ENUM;
  }

  async fetchDescriptionAsync() {
    const maxDescriptionLength = 1000;
    const maxSentence = 5;

    try {
      const searchResponse = await axios.get(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&srsearch=${this.album.artist}%${this.album.title}%album&format=json&origin=*`
      );

      if (!searchResponse.data.query.search[0]) return;
      const pageId = searchResponse.data.query.search[0].pageid;

      const summaryResponse = await axios.get(
        `https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&pageids=${pageId}&format=json&origin=*`
      );

      if (!summaryResponse.data.query.pages[pageId]) return;
      const descriptionsChunks = summaryResponse.data.query.pages[pageId].extract.split(".");

      let resultDescription = "";

      // Limit description length by total length and sentence count
      for (let index = 0; index < maxSentence; index++) {
        const sentence = descriptionsChunks[index];
        if (!sentence) break;
        const combinedLength = resultDescription.length + sentence.length;
        if (combinedLength <= maxDescriptionLength) {
          resultDescription += sentence;
        }
      }

      runInAction(() => {
        this.description = resultDescription;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    }
  }

  async fetchAudioAnalysisMultiTrack() {
    const { backendFunctionUrl, spotifyApi } = appStore;
    try {
      this.loadingSoundWave = true;
      const albumData = await spotifyApi.getAlbum(this.album.id || this.albumId);
      const trackIds = albumData.body.tracks.items.slice(0, 18);
      // populate_waveforms
      // await axios.post(`${backendFunctionUrl}/populate_waveforms`);
      const getWaveFormRes = await axios.post(`${backendFunctionUrl}/get_album_waveforms`, {
        number_of_tracks: trackIds.length,
      });
      let trackLevels = [];
      trackIds.map(async ({ id, name, track_number, duration_ms }, indx) => {
        const waveFormData = getWaveFormRes.data[indx];

        const duration = ((duration_ms / 1000) / 60);
        if (waveFormData) trackLevels.push({ durationMinute: Math.round(duration * 100) / 100, name, track_number, levels: waveFormData.waveForm });
      });
      trackLevels = trackLevels.sort((a, b) => a.track_number - b.track_number);

      runInAction(() => {
        this.loadingSoundWave = false;
        this.loading = false;
        this.trackLevels = trackLevels;
      });
    } catch (err) {
      runInAction(() => {
        this.loading = false;
        this.error = err;
        this.loadingSoundWave = false;
      });
    }
  }

  async fetchAudioAnalysisAsyncSingleTrack() {
    const { headlessMode, backendFunctionUrl } = appStore;

    if (headlessMode && this.songTrackLevel) return;
    try {
      const getWaveFormRes = await axios.post(`${backendFunctionUrl}/get_album_waveforms`, {
        number_of_tracks: 1,
      });
      runInAction(() => {
        this.songTrackLevel = getWaveFormRes.data[0].waveForm;
        this.songDuration = formatMillisecondsToMinutes(this.song.duration_ms);
        this.loadingSoundWave = false;
        this.loading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.loading = false;
        this.error = err;
        this.loadingSoundWave = false;
      });
    }
  }
}
